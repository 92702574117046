import { useSWRConfig } from 'swr'
import { API_PATH } from '@/consts/urls'
import { useModal } from '@/hooks/useModal'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { BOTTOMSHEET_CLOSE_GA_SELECTOR } from './consts'
import FeedbackBottomSheet from './FeedbackBottomSheet'
import { FeedbackContentType, FeedbackProps } from './types'
import { handleFeedbackCreate } from './utils'

const RATINGS = [1, 2, 3, 4, 5]
const POSITIVE_RATINGS = [4, 5]
const DEFAULT_TITLE = '암 전문 의료팀 답변은 어땠나요?'

const TITLE: Record<FeedbackContentType, string> = {
  cancer_content_detail: DEFAULT_TITLE,
  cancer_treatment_detail: DEFAULT_TITLE,
  hospital_detail: DEFAULT_TITLE,
  plus_report_detail: DEFAULT_TITLE,
  clinical_note: 'AI가 정리한 진료 노트 내용은 어땠나요?',
  hospital_reservation: '병원 예약 신청 과정은 어땠나요?',
  qna_detail: '암 전문 의료팀 답변은 어땠나요?',
  qna_me: '암 전문 의료팀 답변은 어땠나요?',
  qna_ask: '질문 등록하는 과정은 어땠나요?',
}

/**
 * 별점으로 선택하는 바텀시트
 */
const StarRatingFeedback = ({
  contentId,
  contentType,
  reasons,
}: FeedbackProps) => {
  const { mutate } = useSWRConfig()
  const { bottomSheetModal } = useModal()

  const showBottomSheet = (rate: number) => {
    const answerType = POSITIVE_RATINGS.includes(rate) ? 'positive' : 'negative'
    handleFeedbackCreate(
      {
        content_id:
          contentType === FeedbackContentType.hospital_reservation
            ? null
            : contentId
            ? parseInt(contentId)
            : null,
        thumbs_up: null,
        star_rating: rate,
        reason: [],
        free_feedback: null,
      },
      contentType,
    ).then((data) => {
      if (!data) return
      bottomSheetModal.show({
        header: {
          title: '',
          size: 'medium',
          closeGaSelector: BOTTOMSHEET_CLOSE_GA_SELECTOR,
        },
        content: (
          <FeedbackBottomSheet
            feedbackId={data.id}
            options={reasons?.[answerType]}
            // 내가 작성한 게시글의 경우 피드백은 한 번만 제출 가능하므로, 완료 후에 mutate하여 피드백 영역을 노출하지 않는다.
            {...(contentType === FeedbackContentType.qna_me
              ? {
                  onUpdateSuccess: () =>
                    mutate(`${API_PATH.Qna}${contentId}/?render_type=html`),
                }
              : {})}
          />
        ),
      })
    })
  }

  return (
    <div className="py-8 px-md text-center flex flex-col items-center">
      <p className="prose-h5">{TITLE[contentType]}</p>

      <p className="text-grey-700 prose-p3 font-medium mt-2 mb-4">
        아래 별점을 선택해 주세요.
      </p>

      <div className="flex gap-x-1">
        {RATINGS.map((rate) => (
          <button
            key={`별점-${rate}`}
            className="w-12 h-12"
            onClick={() => showBottomSheet(rate)}
          >
            <MaterialSymbol
              name="star_fill"
              size={44}
              className="fill-grey-200"
            />
          </button>
        ))}
      </div>
    </div>
  )
}

export default StarRatingFeedback
