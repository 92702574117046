import { Controller, useForm } from 'react-hook-form'
import { useModal } from '@/hooks/useModal'
import useToast from '@/hooks/useToast'
import Button from '@/v1/Button'
import LimitedTextarea from '@/v1/LimitedTextarea'
import Checkbox from '../Checkbox'
import { BOTTOMSHEET_SUBMIT_GA_SELECTOR } from './consts'
import {
  FeedbackForm,
  FeedbackFormResponse,
  FeedbackPositiveOptions,
} from './types'
import { handleFeedbackUpdate } from './utils'

/**
 * 피드백 바텀시트
 * @param feedbackId 해당하는 피드백 id
 * @param options 피드백 항목들
 * @param onUpdateSuccess 피드백 수정이 성공적으로 이루어진 후에 수행할 함수
 */
const FeedbackBottomSheet = ({
  feedbackId,
  options,
  onUpdateSuccess,
}: {
  feedbackId: FeedbackFormResponse['id']
  options?: FeedbackPositiveOptions
  onUpdateSuccess?: () => void
}) => {
  const { showToast } = useToast()
  const { bottomSheetModal } = useModal()

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isValid },
  } = useForm<FeedbackForm>({
    mode: 'onChange',
    defaultValues: {
      reason: [],
      free_feedback: '',
    },
  })

  const { reason: selectedReasons } = watch()

  const onSubmit = (formData: FeedbackForm) => {
    const { free_feedback, ...restData } = formData
    const submitData = {
      ...restData,
      free_feedback: free_feedback?.trim() || null,
    }
    handleFeedbackUpdate(feedbackId, submitData).then(() => {
      onUpdateSuccess?.()
      bottomSheetModal.hide()
      showToast({
        title: '솔직한 의견 전달해 주셔서 감사합니다.',
      })
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {options?.feedbacks ? (
        <h6 className="mb-4 prose-h6">
          왜 그렇게 생각하셨나요?(복수 선택 가능)
        </h6>
      ) : (
        <h6 className="mb-2 prose-h6">해당 점수를 준 이유가 궁금해요.</h6>
      )}
      {options?.feedbacks.map(({ value, label }) => (
        <div className="h-10 flex items-center" key={`option-${value}`}>
          <Controller
            name="reason"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Checkbox
                {...field}
                key={value}
                name="reason"
                value={value}
                onChange={() => {
                  if (!selectedReasons) return
                  const newChecked = selectedReasons?.includes(value)
                    ? selectedReasons.filter((reason) => reason !== value)
                    : [...selectedReasons, value]

                  field.onChange(newChecked)
                }}
              >
                <span className="ml-2 prose-p2 text-grey-900 select-none">
                  {label}
                </span>
              </Checkbox>
            )}
          />
        </div>
      ))}

      {options?.feedbacks ? (
        <h6 className="prose-h6 mb-4 mt-8">
          솔직한 경험을 루닛케어에게 알려주세요.
        </h6>
      ) : (
        <p className="text-grey-700 prose-p3 font-medium mb-4">
          남겨주신 의견은 루닛케어에서 꼼꼼히 확인하여 더 좋은 서비스로 보답
          드릴게요.
        </p>
      )}

      <div className="w-full">
        <Controller
          name="free_feedback"
          control={control}
          render={({ field }) => (
            <LimitedTextarea
              className="border border-grey-200"
              placeholder="이곳에 적어주세요.(선택 사항)"
              maxLength={500}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
      </div>

      <div className="flex py-md w-full">
        <Button
          type="submit"
          size="medium"
          disabled={options?.feedbacks ? !isDirty || !isValid : !isValid}
          data-ga={BOTTOMSHEET_SUBMIT_GA_SELECTOR}
        >
          제출하기
        </Button>
      </div>
    </form>
  )
}

export default FeedbackBottomSheet
