import { API_PATH } from '@/consts'
import { handleError, handleRequest } from '@/utils/httpClient'
import {
  FeedbackContentType,
  FeedbackForm,
  FeedbackFormResponse,
  FeedbackUpdateForm,
} from './types'

export const handleFeedbackCreate = async (
  data: FeedbackForm,
  contentType: FeedbackContentType,
) => {
  try {
    const response = await handleRequest<FeedbackFormResponse>(
      `${API_PATH.Feedback}${contentType}/`,
      {
        method: 'post',
        json: data,
      },
    )
    return response
  } catch (error) {
    handleError(error)
  }
}

export const handleFeedbackUpdate = async (
  feedbackId: number,
  data: FeedbackUpdateForm,
) => {
  try {
    const response = await handleRequest<FeedbackFormResponse>(
      `${API_PATH.Feedback}${feedbackId.toString()}/`,
      {
        method: 'patch',
        json: data,
      },
    )
    return response
  } catch (error) {
    handleError(error)
  }
}
